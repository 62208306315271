<template>
  <div>
    <div class="input-content" :class="isShowError ? 'error-border' : isFocus ? 'actived' : ''">
      <span v-if="!isShowError" class="input-name" :class="isFocus || values ? 'actived' : ''">{{ placeholders }}</span>
      <span v-else class="error-span" :class="isFocus || values ? 'actived' : ''">{{ placeholders }}</span>
      <input v-model="values" autocomplete="off" :type="type2" :placeholder="isFocus ? '' : placeholders" :class="isFocus ? 'actived' : ''" @focus="handleFocus" @blur="handleBlur">
      <a-tooltip placement="bottom">
        <template slot="title">
          <span v-if="type2 == 'password'">Show Password</span>
          <span v-else>Hide Password</span>
        </template>
        <span v-if="type=='password'" class="eyes" :class="type2 == 'text' ? 'biyan' : 'zhengyan'" @click="handlePwd" />
      </a-tooltip>
    </div>
    <div v-if="isShowError" class="error">
      <a-icon type="exclamation-circle" theme="filled" style="font-size: 14px; color: #D93025" />
      <span>{{ errorMsg }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    placeholders: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isFocus: false,
      values: '',
      isShowError: false,
      isShowPassword: false,
      type2: ''
    }
  },
  watch: {
    values(newValue, oldValue) {
      this.$emit('setValue', newValue)
      console.log(newValue)
      this.isShowError = false
    },
    type(newValue, oldValue) {
      this.type2 = newValue
    }
  },
  created() {
    this.$emit('setValue', this.values)
    if (this.type) {
      this.type2 = this.type
    }
  },
  methods: {
    handlePwd() {
      if (this.type2 == 'password') {
        this.type2 = 'text'
      } else {
        this.type2 = 'password'
      }
    },
    handleFocus() {
      this.isFocus = true
    },
    handleBlur() {
      this.isFocus = false
    },
    validate() {
      let result = ''
      if (!this.values) {
        this.isShowError = true
        result = false
      } else {
        result = true
      }
      return result
    },
    validateEmail() {
      const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      let result = ''
      if (reg.test(this.values)) {
        this.isShowError = false
        result = true
      } else {
        this.isShowError = true
        result = false
      }
      return result
    }
  }
}
</script>
<style>
.ant-tooltip-arrow::before{
  background-color: #595959 !important;
}
.ant-tooltip-inner{
  background-color: #595959 !important;
  color: #fff !important;
}
.ant-input:-webkit-autofill {

-webkit-box-shadow: 0 0 0px 1000px rgb(246, 246, 246) inset;

-webkit-text-fill-color: #333;

}
input:-webkit-autofill,

input:-webkit-autofill:hover,

input:-webkit-autofill:focus,

input:-webkit-autofill:active {

-webkit-transition-delay: 99999s;

-webkit-transition: color 99999s ease-out, background-color 99999s ease-out;

}
</style>
<style lang="scss" scoped>
  .input-content{
    width: 100%;
    position: relative;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    border: 1px solid #888888;
    &.actived{
      border: 2px solid #000;
    }
    &.error-border{
      border: 2px solid #D93025;
    }
    .eyes{
      display: inline-block;
      width: 38px;
      height: 38px;
      background: url('../assets/images/login/zhengyanmoren.png') no-repeat;
      background-size: 100% 100%;
      margin-right: 8px;
      &:hover{
        background: url('../assets/images/login/zhengyanmoren.png') no-repeat;
        background-size: 100% 100%;
      }
      &.zhengyan{
        background: url('../assets/images/login/zhengyanmoren.png') no-repeat;
        background-size: 100% 100%;
        &:hover{
          background: url('../assets/images/login/zhengyan.png') no-repeat;
          background-size: 100% 100%;
        }
      }
      &.biyan{
        background: url('../assets/images/login/biyan.png') no-repeat;
        background-size: 100% 100%;
      }
    }
    .input-name{
      display: inline-block;
      font-size: 12px;
      background-color: white;
      line-height: 12px;
      padding: 0 5px;
      position: absolute;
      top: 0;
      left: 12px;
      display: none;
      &.actived{
        animation: input-fade 0.3s forwards;
        display: block;
        position: absolute;
        top: -7px;
        left: 12px;
      }
    }
    .error-span{
      display: inline-block;
      font-size: 12px;
      background-color: white;
      line-height: 12px;
      padding: 0 5px;
      position: absolute;
      top: 0;
      left: 12px;
      color: #D93025;
      display: none;
      &.actived{
        animation: input-fade 0.3s forwards;
        display: block;
        position: absolute;
        top: -7px;
        left: 12px;
      }
    }
    input{
      border-radius: 8px;
      padding: 0 10px;
      box-sizing: border-box;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      flex: 1;
      outline: none;
    }
  }
  .error{
    display: flex;
    align-items: center;
    margin-top: 4px;
    font-size: 12px;
    height: 18px;
    span{
      color: #D93025;
      margin-left: 2px;
    }
  }
  @keyframes input-fade {
  0% {
    opacity: 0;
    top: 10px;
    font-size: 16px;
  }
  25%{
    opacity: 1;
    top: 1px;
    font-size: 14px;
  }
  to {
    opacity: 1;
    top: -7px;
    font-size: 12px;
  }
}
</style>

<template>
  <div class="login">
    <div class="login-left">
      <div class="content">
        <logo class="logo" />
        <h6>Reset your password</h6>
        <h5>We will email you instructions to reset your password.</h5>
        <div class="input-label-wrap">
          <!-- <a-input v-model="email" placeholder="Email" /> -->
          <input-text ref="email" placeholders="Email" error-msg="Please input your Email!" type="text" @setValue="getValue('email',$event)" />
        </div>
        <!-- <div class="save" @click="forgetPasswd">Reset Password</div> -->
        <a-button
          style="width: 100%"
          type="none"
          html-type="submit"
          class="login-form-button"
          :loading="iconLoading"
          @click="forgetPasswd"
        >Reset Password</a-button>
        <p class="new" @click="goLogin">
          <a>Return to Log in</a>
        </p>
      </div>
    </div>
    <common-right />
  </div>
</template>
<script>
import { ForgetPasswd } from '@/api/user'
import inputText from '@/components/inputText'
export default {
  components: {
    inputText
  },
  data() {
    return {
      email: '',
      iconLoading: false
    }
  },
  methods: {
    getValue(name, value) {
      this[name] = value
    },
    async goLogin() {
      this.$router.replace('login')
    },
    async forgetPasswd() {
      this.iconLoading = true
      const { browser } = this.getBrowserInfo()
      const { code, msg } = await ForgetPasswd({
        email: this.email,
        osType: this.GetOSInfo(),
        browerType: browser
      })
      if (code === 200) {
        this.iconLoading = false
        this.$message.success('success')
      } else {
        this.iconLoading = false
        this.$message.error(msg)
      }
      console.log(code)
    },
    // 获取浏览器类型
    getBrowserInfo() {
      var Sys = {}
      var ua = navigator.userAgent.toLowerCase()
      var re = /(msie|firefox|chrome|opera|version).*?([\d.]+)/
      var m = ua.match(re)
      try {
        Sys.browser = m[1].replace(/version/, "'safari")
        Sys.ver = m[2]
      } catch (e) {
        console.log('getBrowserInfo fail.')
      }
      return Sys
    },
    // 操作系统类型
    GetOSInfo() {
      var _bit = ''
      var _pf = navigator.platform
      var appVer = navigator.userAgent
      if (_pf === 'Win32' || _pf === 'Windows') {
        if (appVer.indexOf('WOW64') > -1) {
          _bit = '64位'
        } else {
          _bit = '32位'
        }
        if (
          appVer.indexOf('Windows NT 6.0') > -1 ||
          appVer.indexOf('Windows Vista') > -1
        ) {
          if (_bit === '64位' || appVer.indexOf('Windows Vista') > -1) {
            return 'Windows_vista ' + _bit
          } else {
            return 'Unknow1'
          }
        } else if (
          appVer.indexOf('Windows NT 6.1') > -1 ||
          appVer.indexOf('Windows 7') > -1
        ) {
          if (_bit === '32位' || appVer.indexOf('Windows 7') > -1) {
            return 'Windows_7 ' + _bit
          } else {
            return 'Unknow'
          }
        } else {
          try {
            var _winName = ['2000', 'XP', '2003']
            var _ntNum = appVer.match(/Windows NT 5.\d/i).toString()
            return (
              'Windows_' +
              _winName[_ntNum.replace(/Windows NT 5.(\d)/i, '$1')] +
              ' ' +
              _bit
            )
          } catch (e) {
            return 'Windows'
          }
        }
      } else if (_pf === 'Mac68K' || _pf === 'MacPPC' || _pf === 'Macintosh') {
        return 'Mac'
      } else if (_pf === 'X11') {
        return 'Unix'
      } else if (String(_pf).indexOf('Linux') > -1) {
        return 'Linux'
      } else {
        return 'Unknow'
      }
    }
  }
}
</script>
<style lang="scss">
.login .login-form-button{
  margin-top: 24px;
  background-color: #1f1f1f !important;
  height: 48px;
  line-height: 48px;
  border-radius: 8px;
  font-size: 16px;
  color: white !important;
  &:hover{
    background-color: rgba(31, 31, 31,0.8) !important;
  }
}

</style>
<style scoped lang="scss">
@import "../../assets/css/user.css";
.login .new{
  color: #1f1f1f;
  font-size: 16px;
  font-weight: normal;
  a{
    color: #1f1f1f;
  }
}
</style>
